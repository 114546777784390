.checkboxIcon {
    width: 16px;
    fill: var(--c-primary-sky-blue);
}

.dropdown {
    box-shadow: none;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    margin-top: 2px;
    padding: 0;
}
