.textRadio {
    width: 100%;

    .textRadioLabel {
        display: block;
        margin-bottom: 10px;
        font-size: 13px;
        font-weight: 600;
        color: var(--c-dark-kind-of-dark);
        text-transform: uppercase;
    }

    .textRadioContent {
        border-radius: 12px;
        border: 1px solid var(--c-grey-normal);
        overflow: hidden;
        background-color: var(--c-grey-light);
    }
}