.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 26px 40px;
    border-bottom: 1px solid var(--c-grey-light-200);

    .logotype {
        width: 148px;
    }
}
