.popupFooter {
    display: flex;
    align-items: center;

    .helperText {
        font-size: 13px;
        font-weight: 600;
        color: var(--c-dark-kind-of-dark);
        text-transform: uppercase;
    }

    .buttonOutlined,
    .buttonContained {
        min-width: 116px;
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 600;
        text-transform: none;
        box-shadow: none;
    }

    .buttonGroup {
        margin-left: auto;
    }

    .buttonOutlined {
        margin-left: auto;
        margin-right: 15px;
        color: var(--c-grey-really-dark);
        border-color: var(--c-grey-dark);
        background-color: var(--c-primary-white);
    }

    .buttonContained {
        background-color: var( --c-primary-sky-blue);
    }
}