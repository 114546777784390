// Selectors templates
%title {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: var(--c-grey-really-dark);
}

%value {
    display: block;
    font-size: 15px;
    line-height: 18px;
    color: var(--c-primary-black);
}

// Selectors used
.boxInfo {
    margin-bottom: 30px;
}

.boxInfoTitle {
    margin-bottom: 10px;
    @extend %title;
}

.boxInfoIdTitle {
    margin-bottom: 16px;
    @extend %title;
}

.boxInfoValue {
    @extend %value;
}

.boxInfoId {
    font-size: 23px;
    line-height: 32px;
    font-weight: 700;
}

.boxList {
    display: block;
    padding: 10px 16px;
    margin-bottom: 30px;
    border-radius: 16px;
    background-color: var( --c-grey-light);

    .boxListTitle {
        display: block;
        padding-bottom: 10px;
        margin-bottom: 10px;
        width: 100%;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        color: var(--c-grey-really-dark);
        border-bottom: 1px solid #F0F0F0;
    }

    .boxListRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .boxListSubtitle {
        @extend %title;
    }

    .boxListValue {
        @extend %value;
    }
}
