.avatar {
    width: 90px;
    height: 90px;
    border-radius: 8px;
    border: 0.5px solid var(--c-grey-normal);
    background-color: var(--c-grey-light);
}

.error {
    width: 90px;
    height: 90px;
    border-radius: 8px;
    border: 1px solid var(--c-secondary-red);
    background-color: #fceae6;
}

.icon {
    width: 20px;
    height: 20px;
    fill: var(--c-grey-dark);
}

.button {
    position: relative;
}

.helperText {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 7px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    color: var(--c-primary-white);
    text-transform: uppercase;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) -2.78%, rgba(0, 0, 0, 0) 100%);
}
