.simpleLink {
    display: inline-block;
    margin: 20px 0;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
    color: var(--c-primary-sky-blue);
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        background-color: transparent;
    }
}
