.popup {
    .wrapper {
        border-radius: 20px;
        overflow: hidden;
        box-shadow: none;
    }

    .wrapperBorder {
        border: 1px solid #D3D3D3;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 28px 20px;
        border-bottom: 1px solid #D3D3D3;
    }

    .title {
        font-size: 23px;
        font-weight: 700;
        line-height: 32px;
    }

    .closeButton {
        min-width: 40px;
        height: 40px;
        padding: 0;
        border-radius: 8px;
        border: 1px solid var(--c-grey-normal);
    }

    .closeIcon {
        width: 16px;
        fill: var(--c-dark-kind-of-dark);
    }

    .body {
        padding: 20px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--c-grey-normal);
            border-radius: 6px;
        }

        &::-webkit-scrollbar-button,
        &::-webkit-scrollbar-corner {
            display: none;
        }
    }

    .footer {
        padding: 20px;
        border-top: 1px solid #D3D3D3;
        background-color: #F4F7F8;
    }
}

.popupCenter {
    display: flex;
    align-items: center;

    .wrapper {
        margin: 0 auto;
    }
}

.popupRight {
    display: flex;
    align-items: center;
    left: auto;
    right: 30px;

    .wrapper {
        margin-left: auto;
    }
}
