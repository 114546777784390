.mainBox {
    position: relative;
}

.progressBar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background-color: transparent;
}

.footerLaw {
    position: absolute;
    left: 40px;
    bottom: 19px;
    font-size: 15px;
    color: var(--c-dark-kind-of-dark);
    margin-bottom: 0;
}
