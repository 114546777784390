.layoutContainer {
    display: grid;
    grid-template-columns: 230px calc(100% - 230px);
    grid-template-rows: fit-content(100px) auto;
    min-height: 100vh;

    .layoutHeader {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }

    .layoutNavbar {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .layoutContent {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        padding: 40px;
        background-color: var(--c-grey-light);
    }
}
