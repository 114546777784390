.statusLine {
    display: flex;
    align-items: center;

    .statusLineIndicator {
        display: block;
        min-width: 12px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }

    .statusLineText {
        display: block;
        padding-left: 8px;
        font-size: 15px;
        line-height: 18px;
        color: var(--c-dark);
    }
}
