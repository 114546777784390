.navbar {
    display: flex;
    min-height: 100%;
    padding-left: 40px;

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%;
        padding: 25px 0 40px;
    }

    .navItemIcon {
        width: 18px;
        height: 18px;
        margin-right: 11px;
        fill: var(--c-grey-dark);
        transition-duration: 200ms;
    }

    .navItemLinkActive {
        color: var(--c-primary-black);

        svg {
            fill: var(--c-primary-black);
        }
    }

    .navItemLink {
        display: flex;
        align-items: center;
        padding: 15px 0;
        font-size: 15px;
        font-weight: 600;
        color: var(--c-grey-dark);
        text-decoration: none;
        transition-duration: 200ms;

        &:hover {
            background-color: transparent;
            color: var(--c-primary-black);

            svg {
                fill: var(--c-primary-black);
            }
        }
    }
}
