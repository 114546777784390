:root {
    --c-primary-white: white;
    --c-dark: #000000;

    --c-primary-sky-blue: #00A0E1;
    --c-primary-black: #303030;
    --c-secondary-red: #E12800;
    --c-secondary-green: #75DE50;
    --c-secondary-orange: #F5B819;
    --c-secondary-light-green: #00EC18;

    --c-grey-really-dark: #454F59;
    --c-grey-dark: #8D99A7;
    --c-grey-normal: #CAD0D9;
    --c-grey-light: #F6F8F9;
    --c-grey-light-200: #E3E3E3;

    --c-dark-light: #5D6874;
    --c-dark-kind-of-dark: #737F8B;
}
