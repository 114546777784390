.hero {
    display: flex;
    flex-direction: column;
    min-width: 330px;
    height: 100vh;
    padding: 64px;
    color: #fff;
    background: linear-gradient(0deg, rgb(0 160 225 / 32%), rgb(0 160 225 / 32%)),
        linear-gradient(189.67deg, rgb(0 0 0 / 0%) -26.93%, rgb(0 0 0 / 56%) 56.46%),
        url('../../../../assets/images/loginHeroBg.jpeg');
    background-position: 75%;
    background-size: cover;
}

.logo {
    display: flex;
    align-items: center;
}

.title {
    margin-left: 8px;
}

.name {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
}

.service {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

.desc {
    margin-top: 40px;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
}

.details {
    margin-top: auto;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;

    &Link {
        color: var(--c-primary-sky-blue);
        text-decoration: none;

        &:visited {
            color: var(--c-primary-sky-blue);
        }
    }
}
