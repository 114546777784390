.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .backButton {
        position: absolute;
        top: 40px;
        left: 40px;
    }

    .inner {
        max-width: 360px;
    }

    .title {
        display: block;
        margin-bottom: 8px;
        font-size: 25px;
        font-weight: 700;
        line-height: 29px;
    }

    .description {
        display: block;
        font-size: 18px;
        line-height: 22px;

        span {
            font-weight: 600;
        }
    }

    .form {
        margin-top: 32px;
    }

    .formLabel {
        display: block;
        margin-bottom: 6px;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        color: var(--c-dark-kind-of-dark);
        text-transform: uppercase;
    }

    .character,
    .failedCharacter {
        width: 42px;
        height: 48px;
        border-radius: 12px;
        font-size: 24px;
        font-weight: 400;
        line-height: 48px;
        color: var(--c-grey-really-dark);
    }

    .character {
        background-color: var(--c-grey-light);
        border-color: var(--c-grey-normal);
    }

    .failedCharacter {
        border-color: transparent;
        background-color: #fceae6;
    }

    .characterInactive {
        color: var(--c-grey-dark);
    }

    .formNewCodeMessage {
        display: block;
        margin: 16px 0 0 0;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        color: var(--c-primary-black);
    }

    .formButton {
        margin-top: 16px;
        padding: 0;
        font-size: 18px;
        font-weight: 600;
        color: var(--c-primary-sky-blue);
        text-transform: none;

        &:hover {
            background-color: transparent;
        }
    }

    .errorMessage {
        display: block;
        margin-top: 6px;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        color: var(--c-secondary-red);
        text-transform: uppercase;
    }
}
