.wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 31px;

    .label {
        margin-left: 12px;
        font-size: 15px;
        color: var(--c-dark-light);
    }

    .link {
        text-decoration: none;
    }
}
