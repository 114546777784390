.headerGroup {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    .title {
        margin-bottom: 0;
    }

    .containedButton {
        padding: 7px 14px;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 600;
        box-shadow: none;
        background-color: var(--c-primary-sky-blue);
    }
}
