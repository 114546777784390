.successIcon {
    width: 16px;
    fill: var(--c-secondary-light-green);
    margin-right: 9px;
}

.errorIcon {
    width: 16px;
    fill: var(--c-secondary-red);
    margin-right: 9px;
}
