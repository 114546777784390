.uploadFile {
    .uploadFileBox {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        margin-bottom: 5px;
    }

    .uploadFileResult {
        display: flex;
        align-items: center;
        width: calc(76.5% - 10px);
        height: inherit;
        padding: 0 15px;
        margin-right: 10px;
        border-radius: 10px;
        background-color: #F4F7F8;
        border: 1px solid #D3D3D3;
    }

    .uploadFileName {
        display: block;
        width: 80%;
        font-size: 16px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .uploadFileDelete {
        margin-left: auto;
    }

    .uploadFileDeleteIcon {
        width: 14px;
        fill: var(--c-secondary-red);
    }

    .uploadFileButton {
        justify-content: flex-start;
        width: 100%;
        height: inherit;
        padding: 0 15px;
        border-radius: 10px;
        border: 1px solid #D3D3D3;
        background-color: #F4F7F8;
    }

    .uploadFileButtonText {
        font-size: 16px;
        font-weight: 600;
        color: var(--c-primary-sky-blue);
    }
}

.selectedFile {
    .uploadFileButton {
        justify-content: center;
        width: 23.5%;
        border-color: #F4F7F8;
    }
}

.uploadFileHelperText {
    display: block;
    font-size: 15px;
    color: var(--c-dark-kind-of-dark);
}

.uploadFileError {
    .uploadFileResult {
        border-color: var(--c-secondary-red);
        background-color: '#FCEAE6';
    }
    .uploadFileHelperText {
        color: var(--c-secondary-red);
        text-transform: uppercase;
        font-weight: 600;
    }
}