.actions {
    display: flex;
    align-items: center;

    .actionToggleInner {
        margin-right: 20px;
        padding: 0;

        .actionToggle {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 24px;
            height: 16px;
            padding: 4px;
            border-radius: 8px;
            background-color: var(--c-grey-dark);
            transition-duration: 200ms;

            &:before {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--c-primary-white);
                transition-duration: 200ms;
            }
        }

        .actionToggleActive {
            background-color: var(--c-secondary-green);

            &:before {
                transform: translateX(8px);
            }
        }
    }

    .actionRemove {
        padding: 0;
        margin-right: 27px;
    }

    .actionTooltip {
        padding: 0;
    }

    .actionTooltipIcon {
        width: 18px;
        fill: var(--c-dark-kind-of-dark);
        transition-duration: 200ms;

        &:hover {
            fill: var(--c-primary-sky-blue);
        }
    }
}