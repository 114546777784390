.helperText {
    display: block;
    font-size: 18px;
    line-height: 22px;
    color: var(--c-primary-black);
    word-wrap: break-word;
}

.input {
    margin-bottom: 15px;

    &:last-of-type {
        margin-bottom: 10px;
    }
}

.fieldList {
    margin-bottom: 30px;
}