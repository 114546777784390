.wrapper {
    
    .button {
        height: 48px;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        color: var(--c-primary-sky-blue);
        border-color: var(--c-grey-dark);
        text-transform: none;
    }

    .icon {
        width: 18px;
        fill: var(--c-primary-sky-blue);
    }
}

.field {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    .fieldInput {
        width: calc(100% - 44px);
        margin-bottom: 0;
    }

    .fieldButton {
        margin-left: 6px;
        margin-top: 30px;
        border-radius: 8px;

        &:hover {
            background-color: var(--c-grey-light);
        }
    }

    .fieldIcon {
        width: 16px;
        fill: var(--c-primary-sky-blue);
    }
}