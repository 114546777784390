.header {
    .logotype {
        width: 148px;
    }

    .appBar {
        box-shadow: none;
        border-bottom: 1px solid var(--c-grey-light-200);
    }

    .toolBar {
        padding: 30px 40px;
    }

    .notificationBox {
        position: relative;
        font-size: 0;
        margin-right: 28px;
    }

    .notificationBall {
        position: absolute;
        top: -4px;
        right: -3px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--c-primary-sky-blue);
    }

    .billFilledIcon {
        width: 16px;
        fill: var(--c-primary-black);
    }
}

.popper {
    .accountMenuButtonIcon {
        width: 10px;
        margin-left: 12px;
        fill: var(--c-dark-kind-of-dark);
        transition-duration: 250ms;
    }

    .accountMenuAvatar {
        width: 32px;
        height: 32px;
        border: 2px solid var(--c-grey-really-dark);
        transition-duration: 250ms;
    }

    .accountMenuActiveButton {
        .accountMenuAvatar {
            border-color: var(--c-primary-sky-blue);
        }

        .accountMenuButtonIcon {
            transform: rotateX(180deg) rotateY(0deg);
        }
    }

    .accountMenuAvatarIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border: 2px solid var(--c-grey-really-dark);
        border-radius: 50%;

        svg {
            fill: var(--c-grey-really-dark);
        }
    }

    .accountMenuDropdown {
        position: absolute;
        top: 50px;
        right: 0;
        width: 154px;
        padding: 9px;
        border-radius: 16px;
        box-shadow: none;
        border: 1px solid var(--c-grey-light-200);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 31px;
            width: 12px;
            height: 12px;
            border-top: 1px solid var(--c-grey-light-200);
            border-left: 1px solid var(--c-grey-light-200);
            transform: translateY(-50%) rotate(45deg);
            background-color: var(--c-primary-white);
        }
    }

    .accountMenuLink {
        padding: 15px;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 600;
        color: var(--c-primary-black);
        text-transform: none;
        justify-content: flex-start;
    }

    .accountMenuLinkActive {
        color: var(--c-primary-sky-blue);
    }

    .accountMenuLinkIcon {
        fill: var(--c-dark-kind-of-dark);
    }
}
