.cardAppBox {
    display: flex;
    align-items: center;

    .cardAppBoxFigure {
        width: 64px;
        height: 48px;
        padding: 14px 8px;
        border-radius: 8px;
        background-color: var(--c-grey-light);
    }

    .cardAppBoxImage {
        width: 100%;
        object-fit: contain;
        object-position: center;
    }

    .cardAppBoxText {
        padding-left: 16px;
        font-weight: 600;
    }
}
