.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .content {
        display: flex;
        flex: 1 0 auto;
    }

    .container {
        max-width: 740px;
        width: 100%;
        margin: 0 auto;
        padding: 20px;
        padding-top: 40px;
    }

    .title {
        display: block;
        margin-bottom: 32px;
        font-size: 25px;
        font-weight: 700;
        line-height: 29px;
        color: var(--c-primary-black);
    }

    .description {
        display: block;
        margin-bottom: 32px;
        font-size: 18px;
        line-height: 22px;
        color: var(--c-grey-really-dark);
    }
}
