.phoneNumberLabel {
    display: block;
    margin-bottom: 6px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    color: var(--c-dark-kind-of-dark);
    text-transform: uppercase;
}

.phoneNumberError {
    display: block;
    margin-top: 6px;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: var(--c-secondary-red);
    text-transform: uppercase;
}
