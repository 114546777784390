.fieldList {
    width: 100%;
    padding: 9px 2px 9px 0;
    border-radius: 12px;
    border: 1px solid var(--c-grey-normal);
    background-color: var(--c-grey-light);

    .fieldListContent {
        display: block;
        max-height: 265px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--c-grey-normal);
            border-radius: 6px;
            height: 60px;
        }

        &::-webkit-scrollbar-button,
        &::-webkit-scrollbar-corner {
            display: none;
        }
    }

    .fieldListLabel {
        display: block;
        padding: 0 16px;
        margin: 15px 0 10px 0;
        font-size: 13px;
        font-weight: 600;
        color: var(--c-dark-kind-of-dark);
        text-transform: uppercase;
    }
}
