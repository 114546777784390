.input {
    margin-bottom: 0;
}

.emailTextHelper {
    display: block;
    padding-top: 6px;
    font-size: 15px;
    line-height: 24px;
    color: var(--c-dark-kind-of-dark);
}
