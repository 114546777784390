.footer {
    width: 100%;
    padding: 20px 40px;
    flex: 0 0 auto;

    .footerLaw {
        font-size: 15px;
        color: var(--c-dark-kind-of-dark);
    }
}
