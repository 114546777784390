.textareaWrapper {
    font-size: 0;
}

.label {
    display: block;
    margin-bottom: 6px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    color: var(--c-dark-kind-of-dark);
    text-transform: uppercase;
}

.textarea {
    width: 100%;
    padding: 15px;
    border-radius: 12px;
    font-size: 15px;
    line-height: 18px;
    color: var(--c-primary-black);
    background-color: var(--c-grey-light);
    border: 1px solid var(--c-grey-normal);
    resize: none;
}

.helperText {
    display: block;
    margin-top: 6px;
    font-size: 15px;
    line-height: 18px;
    color: var(--c-dark-kind-of-dark);
}