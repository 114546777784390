.filters {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    .search {
        width: 27.39%;
        margin-right: 20px;
        margin-bottom: 0;
    }

    .selector {
        width: 16.39%;
        margin-bottom: 0;
        margin-right: 20px;

        &:last-of-type {
            margin-right: 0;
        }
    }
}