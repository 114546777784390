.tableContainer {
    width: 100%;
    padding: 24px 0 0;
    border-radius: 8px;
    border: 1px solid var(--c-grey-light-200);

    .tableCellHead {
        padding: 0 20px 18px 0;
        border-bottom: none;
        font-size: 14px;
        line-height: 18px;
        color: var(--c-grey-really-dark);

        &:first-of-type {
            padding-left: 24px;
        }
    }

    .tableSellItem {
        padding: 16px 0;
        padding-right: 20px;
        font-size: 15px;
        line-height: 18px;
        color: var(--c-primary-black);
        border-bottom: 1px solid #f0f0f0;

        &:first-of-type {
            padding-left: 24px;
        }
        &:last-of-type {
            padding-right: 24px;
        }
    }

    .tableRow {
        transition-duration: 200ms;

        &:hover,
        &:active,
        &:focus {
            background-color: #DFF6FF;
        }

        &:last-of-type {
            .tableSellItem {
                border-bottom: none;
                padding-bottom: 16px;
            }
        }
    }
}
