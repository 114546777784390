.wrapper {
    display: flex;
    gap: 15px;
}

.backButton {
    border-color: var(--c-grey-dark);
    color: var(--c-grey-really-dark);

    &:hover {
        background-color: unset;
        border-color: unset;
    }
}
