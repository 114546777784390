.title {
    display: block;
    margin-bottom: 32px;
    font-size: 25px;
    font-weight: 700;
    line-height: 29px;
    color: var(--c-primary-black);
}

.description {
    display: block;
    margin-bottom: 32px;
    font-size: 18px;
    line-height: 22px;
    color: var(--c-grey-really-dark);
}
