.passwordIcon {
    position: relative;
    display: block;
    width: 36px;
    height: 36px;
    padding: 0;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 22px;
        fill: var(--c-primary-sky-blue);
    }
}

.searchIcon {
    width: 16px;
    margin-left: 3px;
    margin-right: -8px;
    fill: var(--c-dark-kind-of-dark);
}
