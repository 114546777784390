.footerContainer {
    display: flex;
    align-items: center;

    .archiveButton {
        margin-left: 20px;
        border-radius: 8px;
        background-color: var(--c-secondary-red);
        text-transform: none;
        box-shadow: none;
    }
}