.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .form {
        min-width: 360px;
    }

    .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 29px;
        margin-bottom: 8px;
    }

    .description {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
    }

    .errorMessage {
        display: block;
        margin-top: 6px;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        color: var(--c-secondary-red);
        text-transform: uppercase;
    }
}
