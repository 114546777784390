@import './layouts.module.scss';
@import './popupFooter.module.scss';
@import './table.module.scss';
@import './cardAppBox.module.scss';

.buttonOutlined {
    font-size: 14px;
    border-radius: 8px;
    border-color: var(--c-grey-dark);
    color: var(--c-grey-really-dark);
    text-transform: none;
    box-shadow: none;
}

.buttonOutlinedPrimary {
    padding: 14px 16px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    border-radius: 8px;
    color: var(--c-primary-white);
    text-transform: none;
    background-color: var(--c-primary-sky-blue);
    box-shadow: none;

    &:hover {
        background-color: var(--c-primary-sky-blue);
    }
}

.inputLabel {
    display: block;
    margin-bottom: 6px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--c-dark-kind-of-dark);
}

.title {
    font-size: 25px;
    font-weight: 700;
    line-height: 42px;
    color: var(--c-primary-black);
}
